import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {LoadingButton} from '@material-ui/lab';
import {useCookie} from 'react-use';

import {API_ROOT, API_HOST} from '@/config';
import {useAlertContext} from '@/hooks/useAlertContext';

import {FormContainer, FormInput} from './styled';
import {constants, Environment} from '../../constants';

interface Props {
  subject?: string;
  category?: string;
}

const ContactFormInner = (props: Props) => {
  const {subject = 'Contact Form', category = 'General Support'} = props;
  const [, setAlertState] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [csrf] = useCookie('csrftoken');
  const [state, setState] = useState({
    name: '',
    from_email: '',
    phonenumber: '',
    message: '',
  });

  const submit = useCallback(
    async (el: FormEvent<HTMLFormElement>) => {
      el.preventDefault();
      const action = el.currentTarget.action;
      setLoading(true);

      if (!window.grecaptcha) {
        setAlertState({
          open: true,
          severity: 'error',
          message: 'ReCaptcha is not ready, please try again later.',
        });
        setLoading(false);
        return;
      }

      try {
        const recaptchaSiteKey =
          constants.ENVIRONMENT === Environment.Production ||
          constants.ENVIRONMENT === Environment.UAT
            ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PRODUCTION
            : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_STAGING;
        // const recaptcha_site_key =
        //   constants.ENVIRONMENT === Environment.Production ||
        //   constants.ENVIRONMENT === Environment.UAT
        //     ? '6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr'
        //     : '6LeeKG0mAAAAAC5P1X96L7CT9nujcDBdFsNzpfYv';
        console.log(
          `${constants.ENVIRONMENT} key is ${recaptchaSiteKey} in contact form inner`,
        );

        const token = await window.grecaptcha.execute(
          '6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr',
          {action: 'contact_form'},
        );
        // const token = await window.grecaptcha.execute(
        //   '6LcddE8mAAAAADdD05bWqssEff39MIPnkEstLjOD',
        //   {action: 'contact_form'},
        // );// for local development

        const response = await fetch(action, {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrf || '',
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ...state,
            subject: `${subject} - ${state.name}`,
            category: `${category}`,
            token: token,
          }),
        });

        if (!response.ok) {
          throw new Error(await response.text());
        }

        setAlertState({
          open: true,
          severity: 'success',
          message:
            'Your contact enquiry has been sent successfully, our team will get back to you as soon as possible',
        });
      } catch (err) {
        setAlertState({
          open: true,
          severity: 'error',
          message:
            err.message || 'An unexpected error occurred, please try again',
        });
      } finally {
        setLoading(false);
      }
    },
    [csrf, setAlertState, state, subject, category],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    setState((prev) => ({...prev, [target.name]: target.value}));
  }, []);

  return (
    <FormContainer>
      <Box width={1000}>
        <Paper>
          <Box padding={7}>
            <form
              action={`${API_HOST}/${API_ROOT}/dashboard/email/send`}
              onSubmit={submit}
            >
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <FormInput
                    type="text"
                    name="name"
                    label="Full Name"
                    placeholder="Enter your full name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    type="email"
                    name="from_email"
                    label="Email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <FormInput
                    type="tel"
                    name="phonenumber"
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <FormInput
                    type="text"
                    name="message"
                    label="What would you like to discuss?"
                    placeholder="Enter your message"
                    onChange={handleChange}
                    multiline
                  />
                </Grid>

                <Grid item>
                  <LoadingButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    pending={loading}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </FormContainer>
  );
};

export default ContactFormInner;
